
const CoursesBanner = () => {
    return (
        <>
             <section className="inner-pagebanner-section text-center pt-50 pb-50 position-relative">
        <div className="container">
            <h1>COURSES</h1>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb justify-content-center">
                    <li className="breadcrumb-item active" aria-current="page">list of Courses in India</li>
                </ol>
            </nav>
        </div>
    </section>
        </>
    )
}
export default CoursesBanner;