

export default function ProfileTheme({children}) {
  return (
    <>
        
            <div className="basic-detials">
                <div className="besic-detials-all-text">
                    {children}
                </div>
            </div>
        
    </>
  )
}
